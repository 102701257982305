import request from '@/axios'
import type { UserType } from './types'

export const loginApi = (data: UserType): Promise<IResponse<string>> => {
  return request.post({ url: '/login/user', data })
}

export const loginOutApi = (): Promise<IResponse> => {
  return request.get({ url: '/login/out' })
}

export const getUserListApi = ({ params }: AxiosConfig) => {
  return request.get<{
    code: string
    data: {
      list: UserType[]
      total: number
    }
  }>({ url: '/mock/user/list', params })
}
